@import "src/scss/variables";

.preview-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #0008;
  z-index: 1050;
  top: 0;
  left: 0;

  .preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  img, video {
    filter: drop-shadow(2px 4px 6px black);
  }

  video {
    max-width: 90%;
    max-height: 90%;
  }

  .action-buttons {
    position: absolute;
    bottom: 10px;
  }

  .action-button {
    color: white;
    font-size: 20px;
    background: #0008;
    border-radius: 50%;
    margin: 0 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #000;
    }
  }

  .btn-prev {
    position: absolute;
    top: 50%;
    left: 10px;
  }

  .btn-next {
    position: absolute;
    top: 50%;
    right: 10px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
