@import "../../node_modules/breakpoint-sass/stylesheets/_breakpoint";

$white-back: #f5f5f5;
$primary: #1c3943;
$primary-dark: #13272e;
$primary-light: #25434e;
$secondary: #dadbe0;
$success: #57ba9e;
$success-light: #69cfb2;
$warning: #fcbe00;
$warning-light: #f9c851;
$danger: #f44336;
$danger-light: #ff5b5b;
$gray: #606676;
$purple: #b44ed5;
$muted: #878c97;
$light: #f0f0f0;

$black: #000;
$white: #ebeff2;

$sidebar: #212121;
$sidebar-dark: #101010;
$sidebar-light: #666;

$blue: #188ae2;
$green: #10c469;
$pink: #ff8acc;
$red: #ff5b5b;
$sky: #71b6f9;
$yellow: #f9c851;

$font-primary: ProximaNova, sans-serif;

$xl: (width 1680px 0);
$lg: (width 1199px 0);
$md: (width 991px 0);
$sm: (width 767px 0);
$xs: (width 576px 0);
$xxs: (width 500px 0);
