@import "src/scss/variables";

.image-edit-section {

  .content {

    .ReactCrop {
      max-width: 480px;
    }

    .image-options {
      .select-box {
        width: 200px;
      }
    }

    .description .panel-heading{
      background-color: #f5f5f5;
    }

    .image {
      width: 100%;
    }

    .alert {
      width: 100%;
      margin: 20px 0 0;
    }
  }

  .footer {
    text-align: right;
  }
}
