@each $name, $color in (
        primary: $primary,
        primary-dark: $primary-dark,
        primary-light: $primary-light,
        secondary: $secondary,
        success: $success,
        warning: $warning,
        warning-light: $warning-light,
        danger: $danger,
        purple: $purple,
        gray: $gray,
        muted: $muted,
        black: $black,
        white: $white,
        white-back: $white-back,
) {
  .text-#{$name} {
    color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }
}