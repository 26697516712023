@import "src/scss/variables";

.project-view {
  margin-bottom: 20px;

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
