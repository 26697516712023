@import "src/scss/variables";

.recent-changes {

  table {

    thead {

      th {
        padding: 5px;
      }
    }

    tbody {

      tr {
        cursor: pointer;

        &.active {
          background: $warning;
          font-weight: bold;
        }

        &:hover {
          font-weight: bold;
        }

        td {
          padding: 5px;

          span {
            overflow: hidden;
            word-break: break-all;
            line-break: anywhere;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          &:first-child {
            padding-left: 10px;
          }

          &:last-child {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
