@import "src/scss/variables";

.image-preview {
  width: 100%;
  height: 100%;
  overflow: auto;

  .no-preview-msg {
    font-size: 20px;
    font-weight: bold;
  }

  .image-edit-section {
    padding: 20px;
  }

  .title-bar {
    background: #c0c0c0;
    border-bottom: 1px solid #d5dbe1;
    padding: 7px 5px;
  }

  .tool-bar {
    background: #ced4da;
    border-bottom: 1px solid #dadee2;
    padding: 7px 5px;
  }

  .button-group {
    text-align: right;

    i {
      cursor: pointer;
      font-size: 20px;
      margin: 0 10px;

      &:not(:hover) {
        color: #888 !important;
      }
    }
  }
}
