
.MuiInputBase-root {
  font-family: $font-primary !important;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary {

  path {
    fill: #d7d7d7;
  }

  &.Mui-checked path {
    fill: $primary;
  }
}

.MuiButtonBase-root {

  &.MuiButton-root {
    color: $primary !important;
    font-family: $font-primary !important;
    font-size: 1rem !important;
    border-radius: 6px !important;
    height: 3rem !important;
    text-transform: none !important;

    &.size-sm {
      height: 2.5rem !important;
      font-size: 0.875rem !important;
    }
  }

  &.MuiIconButton-root {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    color: $primary !important;

    &.size-sm {
      width: 2.125rem !important;
      height: 2.125rem !important;
      font-size: 0.875rem !important;
      padding: 0.25rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  &.Mui-disabled {
    color: $gray !important;
    opacity: 0.6;
  }

  &.btn-primary {
    color: white !important;
    background: $primary !important;
    border-color: $primary !important;

    &:hover:not(:disabled) {
      background: $primary-dark !important;
    }

    &:focus, &:active {
      box-shadow: 0 0 0 0.2rem rgba($primary,.5) !important;
    }
  }

  &.btn-success {
    color: $primary !important;
    background: $success !important;
    border-color: $success !important;

    &:hover:not(:disabled) {
      background: $success-light !important;
    }
  }

  &.btn-warning {
    color: $primary !important;
    background: $warning !important;
    border-color: $warning !important;

    &:hover:not(:disabled) {
      background: $warning-light !important;
    }
  }

  &.btn-danger {
    color: $primary !important;
    background: $danger-light !important;
    border-color: $danger-light !important;

    &:hover:not(:disabled) {
      background: $danger !important;
    }
  }

  &.btn-outline-secondary {
    background: white;
    border: 1px solid $secondary !important;
    color: $gray !important;

    &:focus {
      //background: $secondary !important;
      color: $white !important;
    }
  }

  &.btn-outline-primary {
    background: white;
    border: 1px solid $primary !important;
    color: $primary !important;
    box-shadow: none !important;

    &:focus, &:hover, &:active:not(:disabled) {
      background: $primary !important;
      color: $white !important;
    }
  }
}

button {
  outline: none !important;
}

.dropzone {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 200px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  background-color: #FFF;
  background-image: none;
  text-align: center;
  border: 2px dashed #E5E5E5;

  .dropify-message {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    i {
      font-size: 50px;
      color: #CCC;
    }

    p {
      margin: 5px 0 0;
      line-height: 1.6;
    }
  }

  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .image {
      max-width: 100%;
      filter: drop-shadow(2px 4px 6px #0008);
    }

    .file-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 1.2em;
      display: block;
      text-align: center;
      padding: 5px 10px;
      font-weight: bold;
      color: white;
      background: rgba($primary, 0.7);
      transition: all ease-in-out 0.2s;
    }

    .backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 100%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      background: rgba($primary, 0.7);
      transition: all ease-in-out 0.2s;
    }

    &:hover {

      img {
        filter: blur(2px);
      }

      .file-info {
        bottom: -100%;
      }

      .backdrop {
        bottom: 0;
      }
    }
  }
}
