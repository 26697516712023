@import "../../../../../scss/variables";

.gjs-editor-cont {

  .gjs-two-color {
    color: #ddd !important;
  }

  .gjs-one-bg {
    background-color: #333 !important;
  }

  .gjs-mdl-dialog {
    max-width: 950px !important;
  }

  .tui-image-editor-container {
    position: relative !important;
    z-index: initial !important;
  }

  //#gjs-mdl-c > .gjs-export-dl ~ .gjs-btn-prim:nth-child(3) {
  //  display: none;
  //}

  .gjs-asset-manager {

    .gjs-am-add-field {
      color: white;
    }

    .gjs-am-assets {
      height: calc(100% - 30px);
    }
  }

  #gjs-clm-tags-field svg {
    max-width: 12px;
  }
}


.icon-add-comp::before, .icon-comp100::before,.icon-comp50::before,.icon-comp30::before,.icon-rm::before{ content: '';}
.icon-add-comp {
  background: url(/resources/images/grapesjs/icon-sq-a.png) no-repeat center;
}
.icon-comp100 {
  background: url(/resources/images/grapesjs/icon-sq-1.png) no-repeat center;
}
.icon-comp50 {
  background: url(/resources/images/grapesjs/icon-sq-2.png) no-repeat center;
}
.icon-comp30 {
  background: url(/resources/images/grapesjs/icon-sq-3.png) no-repeat center;
}
.icon-rm {
  background: url(/resources/images/grapesjs/icon-sq-r.png) no-repeat center;
}

.icons-flex {
  background-size: 70% 65% !important;
  height: 15px;
  width: 17px;
  opacity: 0.9;
}
.icon-dir-row {
  background: url(/resources/images/grapesjs/flex-dir-row.png) no-repeat center;
}
.icon-dir-row-rev {
  background: url(/resources/images/grapesjs/flex-dir-row-rev.png) no-repeat center;
}
.icon-dir-col {
  background: url(/resources/images/grapesjs/flex-dir-col.png) no-repeat center;
}
.icon-dir-col-rev {
  background: url(/resources/images/grapesjs/flex-dir-col-rev.png) no-repeat center;
}
.icon-just-start{
  background: url(/resources/images/grapesjs/flex-just-start.png) no-repeat center;
}
.icon-just-end{
  background: url(/resources/images/grapesjs/flex-just-end.png) no-repeat center;
}
.icon-just-sp-bet{
  background: url(/resources/images/grapesjs/flex-just-sp-bet.png) no-repeat center;
}
.icon-just-sp-ar{
  background: url(/resources/images/grapesjs/flex-just-sp-ar.png) no-repeat center;
}
.icon-just-sp-cent{
  background: url(/resources/images/grapesjs/flex-just-sp-cent.png) no-repeat center;
}
.icon-al-start{
  background: url(/resources/images/grapesjs/flex-al-start.png) no-repeat center;
}
.icon-al-end{
  background: url(/resources/images/grapesjs/flex-al-end.png) no-repeat center;
}
.icon-al-str{
  background: url(/resources/images/grapesjs/flex-al-str.png) no-repeat center;
}
.icon-al-center{
  background: url(/resources/images/grapesjs/flex-al-center.png) no-repeat center;
}

[data-tooltip]::after {
  background: rgba(51, 51, 51, 0.9);
}

.gjs-pn-commands {
  min-height: 40px;
}

#gjs-sm-float,
.gjs-pn-views .fa-cog {
  display: none;
}

.gjs-logo-version {
  background-color: #756467;
}

.gjs-pn-panel.gjs-pn-views {
  padding: 0;
  border-bottom: none;
}

.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.gjs-pn-views .gjs-pn-btn {
  margin: 0;
  height: 40px;
  padding: 10px;
  width: 33.3333%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

.CodeMirror {
  min-height: 450px;
  margin-bottom: 8px;
}
.grp-handler-close {
  background-color: transparent;
  color: #ddd;
}

.grp-handler-cp-wrap {
  border-color: transparent;
}


@media (max-width: 1280px) {
  .gjs-editor-cont {

    .gjs-cv-canvas {
      width: 75%;
    }

    .gjs-pn-panels {

      .gjs-pn-commands {
        width: 75%;
      }

      .gjs-pn-options {
        right: 25%;
      }

      .gjs-pn-views {
        width: 25%;
      }

      .gjs-pn-views-container {
        width: 25%;
      }
    }
  }
}
