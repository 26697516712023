@import "src/scss/variables";

.grid-view-item {
  margin: 10px;
  position: relative;
  width: 150px;

  .image {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-clip: padding-box;

    .section-name {
      bottom: 0;
      font-size: 14px;
      width: 100%;
      display: block;
      text-align: center;
      padding: 5px 10px;
      font-weight: bold;
      box-shadow: inset 0 0 10px #0005;
      transition: all ease-in 0.2s;
    }
  }

  .title {
    text-align: center;
    padding: 10px 0;
    word-break: break-word;
    word-wrap: break-word;
    font-size: 16px;
    color: gray;
    font-weight: bold;
  }

  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $red;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .video-frame {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-frame-img {
      position: absolute;
      width: 50px;
    }
  }
}
